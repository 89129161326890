import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import {
  mainColor,
  subTextColor,
  chevronStyle,
  placeholderColor,
  breakpoint,
} from '../../../components/theme';
import { RouteComponentProps } from '@reach/router';
import { navigate } from '@reach/router';
import { CROCCHA_API } from 'app/src/utils';
// import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

type FormData = {
  companyName: string;
  name: string;
  email: string;
  phoneNumber: string;
  howToContact: string;
  shopURL: string;
  availableContactHours: string;
  interviewDay: string;
  other: string;
  subject?: string;
};

type Res = {
  result: 'success' | 'failed';
};

type Status = 'standby' | 'success' | 'error' | 'working';

const useFetchPost = (url: string, success: () => void) => {
  const [submitStatus, setSubmitStatus] = useState<Status>('standby');

  const sendMail = (data: FormData) => {
    setSubmitStatus('working');
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res: Res) => {
        if (res.result === 'success') {
          success();
          setSubmitStatus('success');
        } else {
          throw Error(res.result);
        }
      })
      .catch((e) => {
        console.error(e.message);
        setSubmitStatus('error');
      });
  };

  return { submitStatus, sendMail };
};

type ECMovieFormProps = RouteComponentProps<{ fromPage?: 'contact' }>;

const ECMovieForm: React.FC<ECMovieFormProps> = () => {
  const { handleSubmit, register, errors, watch } = useForm<FormData>({
    mode: 'onChange',
  });
  const url = CROCCHA_API.ALB_HOST + '/contacts/ecmovieparlor';
  const { sendMail, submitStatus } = useFetchPost(url, () =>
    navigate('/success', { state: { fromPage: 'contact' } })
  );
  const selectboxDefaultValue = 'メール、電話どちらでも可';
  const isSelectboxDefault =
    watch('subject') == null || watch('subject') === selectboxDefaultValue;

  const onSubmit = (data: FormData) => {
    // trackCustomEvent({
    //   // string - required - The object that was interacted with (e.g.video)
    //   category: 'request_submit',
    //   // string - required - Type of interaction (e.g. 'play')
    //   action: 'submit',
    //   // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
    //   label: '[EC動画パーラー]新規お問い合わせ',
    // });

    const submitData: FormData = {
      ...data,
      other: data.other?.replace(/\r?\n/g, '<br>') ?? '',
      subject: `[EC動画パーラー]新規お問い合わせ`,
    };
    sendMail(submitData);
  };

  return (
    <>
      {/* メンテナンス、普段は非表示 */}
      <Maintenance>
        ただいまメンテナンス中です。
        <br />
        復旧は2月16日14時頃を予定しております。
      </Maintenance>

      {/* フォーム */}
      <FormWrap>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FieldWrap>
            <LabelWrap>
              <Label htmlFor='companyName'>会社名</Label>
              {errors?.companyName?.message && (
                <ErrorText>{errors.companyName.message}</ErrorText>
              )}
            </LabelWrap>
            <Input
              type='text'
              name='companyName'
              placeholder='例: トライアングル株式会社'
              ref={register({
                required: '必須項目です',
                maxLength: {
                  value: 100,
                  message: '100文字以内で入力してください',
                },
              })}
              hasError={errors?.companyName?.message}
            />
          </FieldWrap>

          <FieldWrap>
            <LabelWrap>
              <Label htmlFor='name'>ご担当者名</Label>
              {errors?.name?.message && (
                <ErrorText>{errors.name.message}</ErrorText>
              )}
            </LabelWrap>
            <Input
              type='text'
              name='name'
              placeholder='例: 山田花子'
              ref={register({
                required: '必須項目です',
                maxLength: {
                  value: 100,
                  message: '100文字以内で入力してください',
                },
              })}
              hasError={errors?.name?.message}
            />
          </FieldWrap>

          <FieldWrap>
            <LabelWrap>
              <Label htmlFor='email'>メールアドレス</Label>
              {errors?.email?.message && (
                <ErrorText>{errors.email.message}</ErrorText>
              )}
            </LabelWrap>
            <Input
              type='text'
              name='email'
              placeholder='例: xxx@sample.jp'
              ref={register({
                required: '必須項目です',
                maxLength: {
                  value: 100,
                  message: '100文字以内で入力してください',
                },
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: 'メールアドレスの形式が正しくありません',
                },
              })}
              hasError={errors?.email?.message}
            />
          </FieldWrap>

          <FieldWrap>
            <LabelWrap>
              <Label htmlFor='phoneNumber'>電話番号</Label>
              {errors?.phoneNumber?.message && (
                <ErrorText>{errors.phoneNumber.message}</ErrorText>
              )}
            </LabelWrap>
            <Input
              type='text'
              name='phoneNumber'
              placeholder='例: 06-7777-0945'
              ref={register({
                required: '必須項目です',
                maxLength: {
                  value: 100,
                  message: '100文字以内で入力してください',
                },
                // pattern: {
                //   value: //,
                //   message: '電話番号の形式が正しくありません',
                // },
              })}
              hasError={errors?.phoneNumber?.message}
            />
          </FieldWrap>

          <FieldWrap>
            <LabelWrap>
              <Label htmlFor='shopURL'>HPまたはshop URL</Label>
              {errors?.shopURL?.message && (
                <ErrorText>{errors.shopURL.message}</ErrorText>
              )}
            </LabelWrap>
            <Input
              type='text'
              name='shopURL'
              placeholder='例: https://douga-parlor.jp'
              ref={register({
                required: '必須項目です',
                maxLength: {
                  value: 100,
                  message: '100文字以内で入力してください',
                },
              })}
              hasError={errors?.shopURL?.message}
            />
          </FieldWrap>

          <FieldWrap>
            <Label htmlFor='howToContact'>お問い合わせ項目</Label>
            <SelectWrap>
              <Select
                name='howToContact'
                ref={register}
                isDefault={isSelectboxDefault}
                defaultValue={selectboxDefaultValue}
              >
                <option value={selectboxDefaultValue}>
                  {selectboxDefaultValue}
                </option>
                <option>メールで希望</option>
                <option>電話で希望</option>
              </Select>
            </SelectWrap>
          </FieldWrap>

          <FieldWrap>
            <LabelWrap>
              <Label htmlFor='availableContactHours'>
                ご連絡可能時間帯（任意）
              </Label>
              {errors?.availableContactHours?.message && (
                <ErrorText>{errors.availableContactHours.message}</ErrorText>
              )}
            </LabelWrap>
            <Input
              type='text'
              name='availableContactHours'
              placeholder='例: 平日10:00~16:00'
              ref={register({
                maxLength: {
                  value: 100,
                  message: '100文字以内で入力してください',
                },
              })}
              hasError={errors?.availableContactHours?.message}
            />
          </FieldWrap>

          <FieldWrap>
            <LabelWrap>
              <Label htmlFor='interviewDay'>面談ご希望日（任意）</Label>
              {errors?.interviewDay?.message && (
                <ErrorText>{errors.interviewDay.message}</ErrorText>
              )}
            </LabelWrap>
            <Input
              type='text'
              name='interviewDay'
              placeholder='例: ○月○日、○月○日'
              ref={register({
                maxLength: {
                  value: 100,
                  message: '100文字以内で入力してください',
                },
              })}
              hasError={errors?.interviewDay?.message}
            />
          </FieldWrap>

          <TextareaFieldWrap>
            <LabelWrap>
              <Label htmlFor='other'>その他（任意）</Label>
              {errors?.other?.message && (
                <ErrorText>{errors.other.message}</ErrorText>
              )}
            </LabelWrap>
            <Input
              as='textarea'
              name='other'
              rows='5'
              placeholder=''
              ref={register({
                maxLength: {
                  value: 400,
                  message: '400文字以内で入力してください',
                },
              })}
              hasError={errors?.other?.message}
            />
          </TextareaFieldWrap>

          {submitStatus === 'error' && (
            <div>
              <p>お問い合わせを送信できませんでした。</p>
              <p>
                恐れ入りますがしばらくお待ちいただき、後ほどお試しいただけますでしょうか
              </p>
            </div>
          )}

          <SubmitButton type='submit' value='無料相談する' />
        </form>
      </FormWrap>

      <ContactBox>
        <ContactBoxDesc>まずはお気軽にお問い合わせください！</ContactBoxDesc>
        <div>
          <ContactBoxTel>
            <span>TEL</span> <a href='tel:0677770945'>06-7777-0945</a>
          </ContactBoxTel>
          <ContactBoxTime>
            <span>受付</span>平日10:00~17:00
          </ContactBoxTime>
        </div>
      </ContactBox>
    </>
  );
};

export default ECMovieForm;

const MainColor = '#ff8574';

const Maintenance = styled.section`
  margin: 0 auto;
  max-width: 800px;
  padding: 70px 1rem 100px;
  text-align: center;
  /* NOTE：メンテナンス時に無効にする */
  display: none;
`;

const FormWrap = styled.section`
  margin: 0 auto;
  max-width: 800px;
  padding: 70px 1rem 40px;

  /* NOTE：メンテナンス時に有効にする */
  /* display: none; */
`;

const LabelWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  white-space: nowrap;
`;

const ErrorText = styled.span`
  margin-bottom: 0.5rem;
  display: inline-block;
  color: ${mainColor};
  font-size: 12px;
`;

const FieldWrap = styled.div`
  margin-bottom: 43px;
  width: 100%;
  @media ${breakpoint.sp} {
    width: 100%;
  }
`;

const inputMixinStyle = css`
  display: block;
  padding: 0.5rem 1rem;
  width: 100%;
  background: #f8f8f8;
  border: 1px solid #a2a1a1;
  border-radius: 5px;
  &::placeholder {
    color: ${placeholderColor};
  }
  &:focus {
    border: 1px solid #d1a4a1;
    box-shadow: 0px 2px 6px #3c0e0e1f;
    background: #fff;
  }
  ${(props: any) =>
    props.hasError &&
    css`
      border: 1px solid ${mainColor};
      background: #fff5f5;
      box-shadow: 0px 2px 6px #3c0e0e1f;
      color: ${mainColor};
    `}
`;

const Input: any = styled.input`
  ${inputMixinStyle};
`;

const SelectWrap = styled.div`
  position: relative;

  &::after {
    ${chevronStyle};
    position: absolute;
    top: 14px;
    left: auto;
    right: 10px;
    border-color: ${subTextColor};
    pointer-events: none;
  }
`;

const Select: any = styled.select`
  position: relative;
  ${inputMixinStyle};
`;

const TextareaFieldWrap = styled.div`
  margin-bottom: 40px;
  width: 100%;
`;

const SubmitButton = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  background: #6ec199;
  color: #fff;
  font-size: 30px;
  font-weight: bold;

  margin: 0 auto;
  width: 470px;
  height: 90px;

  @media ${breakpoint.sp} {
    width: 80%;
    height: 60px;
    font-size: 18px;
  }

  :hover,
  :active,
  :focus {
    opacity: 0.7;
    color: #fff;
  }
`;

// お気軽に〜〜〜
const ContactBox = styled.div`
  margin: 0 auto;
  padding-bottom: 40px;
  text-align: center;

  & > div {
    display: inline-block;
    margin: auto;
    text-align: left;
  }
`;

const ContactBoxDesc = styled.p`
  font-size: 24px;
  font-weight: bold;

  @media ${breakpoint.sp} {
    font-size: 16px;
  }
`;

const ContactBoxTel = styled.p`
  font-size: 30px;
  font-weight: bold;

  @media ${breakpoint.sp} {
    font-size: 25px;
  }

  & > span {
    font-size: 13px;
    background: ${MainColor};
    padding: 4px 8px;
    color: #fff;
    border-radius: 4px;
  }
`;

const ContactBoxTime = styled.p`
  font-size: 18px;

  @media ${breakpoint.sp} {
    font-size: 14px;
  }

  & > span {
    font-size: 13px;
    font-weight: bold;
    background: ${MainColor};
    margin-right: 8px;
    padding: 4px 8px;
    color: #fff;
    border-radius: 4px;
  }
`;
