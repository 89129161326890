// other
import useMedia from 'app/src/customHooks/userMedia';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

type MovieRequestButtonProps = {
  scrollTo: (id: string) => void;
};

const MovieRequestButton: React.FC<MovieRequestButtonProps> = ({
  scrollTo,
}) => {
  const isMobile = useMedia('(max-width: 767px)');
  const [show, setShow] = useState(true);

  // スクロールしたら閉じる
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    const isMobile2 = document.body.getBoundingClientRect().width < 768;
    const scrollPos = isMobile2 ? -10000 : -13300;
    setShow(document.body.getBoundingClientRect().top > scrollPos);

    // console.log(document.body.getBoundingClientRect());
  };

  return (
    <RequestButtonImg
      src='https://static.croccha.com/company/ec-movie/request_document_05.png?w=160'
      alt='今すぐ無料相談する'
      width={isMobile ? '100' : '160'}
      height={isMobile ? '100' : '160'}
      onClick={() => scrollTo('requestForm')}
      show={show}
    ></RequestButtonImg>
  );
};

export default MovieRequestButton;

// 無料相談ボタン（fixed）
const RequestButtonImg = styled.img`
  position: fixed;
  /*基準を画面の左上に*/
  bottom: 8px;
  right: 8px;
  /*余白が入らないように*/
  margin: 0;

  display: ${(p) => (p.show ? 'show' : 'none')};

  :hover,
  :active,
  :focus {
    opacity: 0.7;
    color: #fff;
    cursor: pointer;
  }
`;
