// other
import {
  PosterSneaker,
  PosterEmbossedHeater,
  PosterDriedFruits,
  PosterSneakerMP4,
  PosterEmbossedHeaterMP4,
  PosterDriedFruitsMP4,
} from 'app/src/utils';
import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoint } from '../../theme';

const MovieSampleMovie = () => {
  const youtubedatas = [
    { id: 'bsznvMgT8n0', desc: '使い方動画を載せればお客様も安心！' },
    { id: 'fAuvDA1Y0dA', desc: '使い方動画を載せればお客様も安心！' },
    {
      id: 'jkFvRYpe0uQ',
      desc:
        '高いものは買うのが慎重になる！動画で使用イメージやサイズを確認して安心！',
    },
  ];
  const mp4datas = [
    {
      url: PosterSneakerMP4,
      poster: PosterSneaker,
      desc:
        'サンプル動画1: スニーカーのご紹介\n箱の開封から靴を手に取って色や生地がわかりやすいように撮影しています。',
    },
    {
      url: PosterEmbossedHeaterMP4,
      poster: PosterEmbossedHeater,
      desc:
        'サンプル動画2: エンボスヒーターの使い方紹介\n開封からスイッチの説明、使う際の注意点などをテロップ付きでご紹介しています。',
    },
    {
      url: PosterDriedFruitsMP4,
      poster: PosterDriedFruits,
      desc:
        'サンプル動画3: ドライフルーツのご紹介\n開封してどのくらい入っているのか、どのような質感、大きさなのか見えやすいように撮影しています。',
    },
  ];
  return (
    <>
      {/* {youtubedatas.map((youtube) => (
          <SampleMovieWrap>
            <WrapYoutube>
              <IframeWrap
                id='ytplayer'
                src={`https://www.youtube.com/embed/${youtube.id}?controls=1&modestbranding=1&rel=0&origin=http://example.com&listType=user_uploads`}
              />
            </WrapYoutube>
            <p>{youtube.desc}</p>
          </SampleMovieWrap>
        ))} */}
      {mp4datas.map((movie) => (
        <SampleMovieWrap>
          <SampleVideo
            src={movie.url}
            poster={movie.poster}
            muted
            playsInline
            controls
            preload='metadata'
          ></SampleVideo>
          <SampleMovieDesc>
            {movie.desc.split('\n').map((text, i) => (
              <span key={i}>
                {text} <br />
              </span>
            ))}
          </SampleMovieDesc>
        </SampleMovieWrap>
      ))}
    </>
  );
};

export default MovieSampleMovie;

const VideoSize = css`
  width: 700px;
  height: calc(700px * 0.562);

  @media ${breakpoint.sp} {
    width: calc(100vw - 48px);
    height: calc((100vw - 48px) * 0.562);
  }

  @media ${breakpoint.tabOnly} {
    width: calc(100vw - 64px);
    height: calc((100vw - 64px) * 0.562);
  }
`;

const SampleVideo = styled.video`
  ${VideoSize};
  display: inline-block;
`;

const SampleMovieWrap = styled.div`
  text-align: center;
  max-width: 700px;
  margin: 0 auto 32px;

  @media ${breakpoint.sp} {
    margin: 0 16px 32px;
  }
`;

const SampleMovieDesc = styled.p`
  text-align: left;

  @media ${breakpoint.sp} {
    font-size: 12px;
  }
`;
