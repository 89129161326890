// other
import React from 'react';
import styled from 'styled-components';

import deliveryStep01 from '../../../../static/images/deliveryStep01.svg';
import deliveryStep02 from '../../../../static/images/deliveryStep02.svg';
import deliveryStep03 from '../../../../static/images/deliveryStep03.svg';
import deliveryStep05 from '../../../../static/images/deliveryStep05.svg';
import { breakpoint } from '../../theme';

const MovieSteps = () => (
  <MovieStepsWrap>
    <Gridbox>
      <GridItemLeft>
        <img src={deliveryStep01} alt='まずはお問い合わせ'></img>
        <TriangleGray />
      </GridItemLeft>
      <GridItem>
        <div>
          <DeliveryStepTitle>
            無料相談後にメールでお申込み書を送付いたします
          </DeliveryStepTitle>
          <DeliveryStepP>
            まずは初回の無料相談をさせていただきます。
            <br />
            先着順となりますのでお申込みを頂いたタイミングによってはお待ち頂く可能性がございます。
          </DeliveryStepP>
        </div>
      </GridItem>

      <GridItemLeft>
        <img src={deliveryStep02} alt='ヒアリング面談'></img>
        <TriangleGray />
      </GridItemLeft>
      <GridItem>
        <div>
          <DeliveryStepTitle>商品をお送りください</DeliveryStepTitle>
          <DeliveryStepP>
            お申込み書に添ってまずはサンプル動画（無料）を撮影いたします。
            <br />
            お使いになられたいロゴ、写真、BGMなどがある場合は一緒にお送りください。BGMはお任せも可能です。
          </DeliveryStepP>
        </div>
      </GridItem>

      <GridItemLeft>
        <img src={deliveryStep03} alt='サンプル動画制作'></img>
        <TriangleGray />
      </GridItemLeft>
      <GridItem>
        <div>
          <DeliveryStepTitle>サンプル動画をご確認ください</DeliveryStepTitle>
          <DeliveryStepP>
            サンプル動画にて、撮影イメージがあっているかどうかご確認いただけます。
            <br />
            問題なければ、完成したサンプル動画を元に他の商品の撮影編集を進めさせて頂きます。
          </DeliveryStepP>
        </div>
      </GridItem>

      <GridItemLeft>
        <img src={deliveryStep05} alt='制作 / 納品'></img>
      </GridItemLeft>
      <GridItem>
        <div>
          <DeliveryStepTitle>制作・納品となります</DeliveryStepTitle>
          <DeliveryStepP>
            ご確認後に順次お送りいただいた商品にて動画を制作・納品させていただきます。
          </DeliveryStepP>
        </div>
      </GridItem>
    </Gridbox>
  </MovieStepsWrap>
);

export default MovieSteps;

const MovieStepsWrap = styled.div`
  margin: 16px 10px;
`;

// ご依頼から納品までの流れ
const Gridbox = styled.div`
  display: grid;
  grid-template-rows: 180px 180px 180px 150px;
  grid-template-columns: 150px 1fr;
  max-width: 1000px;
  margin: 0 auto;

  @media ${breakpoint.sp} {
    grid-template-rows: 150px 150px 150px 100px;
    grid-template-columns: 100px 1fr;
    font-size: 12px;
  }
`;

const GridItemLeft = styled.div`
  & > img {
    margin-top: 20px;
  }
`;

const GridItem = styled.div`
  position: relative;

  & > div {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%) translateX(0%);
    -webkit-transform: translateY(-50%) translateX(0%);
    margin-left: 20px;
  }
`;

const TriangleGray = styled.div`
  margin: 6px auto auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 16.5px 0 16.5px;
  border-color: #c4c4c4 transparent transparent transparent;

  @media ${breakpoint.sp} {
    border-width: 10px 8px 0 8px;
  }
`;

const DeliveryStepTitle = styled.p`
  font-size: 24px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 12px;

  @media ${breakpoint.sp} {
    font-size: 14px;
  }
`;

const DeliveryStepP = styled.p`
  font-size: 20px;

  @media ${breakpoint.sp} {
    font-size: 12px;
  }
`;
