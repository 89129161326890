// other
import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../theme';

const MovieQuestion = () => (
  <>
    <QuestionWrap>
      <GQ1>
        <GIQuestion>
          <SpanQ>Q.</SpanQ>
        </GIQuestion>
        <GIQuestion>
          <QTitle>どうしてこんなに安いんでしょうか？</QTitle>
        </GIQuestion>
        <GIQuestion>
          <SpanA>A.</SpanA>
        </GIQuestion>
        <GIQuestion>
          <QAnswer>
            すでに動画のテンプレートを制作して量産できる体制にしているため、この価格で動画を制作できます。
            さまざまな商品の種類に対応しているため、お気軽にご相談ください。
          </QAnswer>
        </GIQuestion>
      </GQ1>
    </QuestionWrap>

    <QuestionWrap>
      <GQ2>
        <GIQuestion>
          <SpanQ>Q.</SpanQ>
        </GIQuestion>
        <GIQuestion>
          <QTitle>
            初めて動画を制作するのですが、どのように進めたらいいのか分かりません。大丈夫ですか？
          </QTitle>
        </GIQuestion>
        <GIQuestion>
          <SpanA>A.</SpanA>
        </GIQuestion>
        <GIQuestion>
          <QAnswer>
            はい。初回はヒアリング面談をさせていただきますので、その内容をもとにプランをご案内させていただきます。動画制作だけでなく、ネットショップのノウハウがありますので、お困りごとも何でも相談ください。
          </QAnswer>
        </GIQuestion>
      </GQ2>
    </QuestionWrap>

    <QuestionWrap>
      <GQ3>
        <GIQuestion>
          <SpanQ>Q.</SpanQ>
        </GIQuestion>
        <GIQuestion>
          <QTitle>サンプル動画は無料で制作できるのでしょうか？</QTitle>
        </GIQuestion>
        <GIQuestion>
          <SpanA>A.</SpanA>
        </GIQuestion>
        <GIQuestion>
          <QAnswer>
            はい、サンプル動画は、商品をお送りいただければ無料で制作いたします。
            <br />
            紹介動画を制作するかどうか決められていない、試しに動画を作ってみたい方でも大歓迎です。
            <br />
            こだわりがある方にはプロプランもご用意しており、別途お見積りさせて頂きます。
          </QAnswer>
        </GIQuestion>
      </GQ3>
    </QuestionWrap>

    <QuestionWrap>
      <GQ4>
        <GIQuestion>
          <SpanQ>Q.</SpanQ>
        </GIQuestion>
        <GIQuestion>
          <QTitle>事前に用意するものはなんですか？</QTitle>
        </GIQuestion>
        <GIQuestion>
          <SpanA>A.</SpanA>
        </GIQuestion>
        <GIQuestion>
          <QAnswer>
            ネットショップのURLだけで大丈夫です。
            商品のジャンルや大きさによって、動画の見せ方が変わってきますので、動画にしたい商品が決まっているとよりスムーズにお話しさせていただけるかと思います。
          </QAnswer>
        </GIQuestion>
      </GQ4>
    </QuestionWrap>

    <GQ5>
      <GIQuestion>
        <SpanQ>Q.</SpanQ>
      </GIQuestion>
      <GIQuestion>
        <QTitle>動画の納品形式は？二次使用は出来ますか？</QTitle>
      </GIQuestion>
      <GIQuestion>
        <SpanA>A.</SpanA>
      </GIQuestion>
      <GIQuestion>
        <QAnswer>
          YouTubeへのアップロードや一般的によく使用されているmp4形式で納品いたします。
          <br />
          二次使用については、用途によって異なるため営業担当にお問い合わせください。
        </QAnswer>
      </GIQuestion>
    </GQ5>
  </>
);

export default MovieQuestion;

const GQBase = styled.div`
  display: grid;
  grid-template-rows: 80px 100px;
  grid-template-columns: 60px 1fr;
  max-width: 1000px;
  margin: auto;

  @media ${breakpoint.tabOnly} {
    grid-template-rows: 50px 90px;
  }

  @media ${breakpoint.sp} {
    grid-template-rows: 40px 60px;
  }
`;

// よくお問い合わせいただくご質問
const GQ1 = styled(GQBase)`
  grid-template-rows: 60px 100px;

  @media ${breakpoint.tabOnly} {
    grid-template-rows: 50px 90px;
  }

  @media ${breakpoint.sp} {
    grid-template-rows: 40px 90px;
  }
`;

const GQ2 = styled(GQBase)`
  grid-template-rows: 100px 100px;

  @media ${breakpoint.tabOnly} {
    grid-template-rows: 80px 120px;
  }

  @media ${breakpoint.sp} {
    grid-template-rows: 100px 120px;
  }
`;

const GQ3 = styled(GQBase)`
  grid-template-rows: 80px 100px;

  @media ${breakpoint.tabOnly} {
    grid-template-rows: 80px 120px;
  }

  @media ${breakpoint.sp} {
    grid-template-rows: 80px 120px;
  }
`;

const GQ4 = styled(GQBase)`
  grid-template-rows: 80px 100px;

  @media ${breakpoint.tabOnly} {
    grid-template-rows: 80px 120px;
  }

  @media ${breakpoint.sp} {
    grid-template-rows: 50px 120px;
  }
`;

const GQ5 = styled(GQBase)`
  grid-template-rows: 80px 100px;

  @media ${breakpoint.tabOnly} {
    grid-template-rows: 80px 120px;
  }

  @media ${breakpoint.sp} {
    grid-template-rows: 60px 120px;
  }
`;

const GIQuestion = styled.div`
  margin: auto 10px;
`;

const SpanQ = styled.span`
  font-size: 32px;
  color: #4159d3;

  @media ${breakpoint.sp} {
    font-size: 22px;
  }
`;

const SpanA = styled.span`
  font-size: 32px;
  color: #ff8574;

  @media ${breakpoint.sp} {
    font-size: 22px;
  }
`;

const QTitle = styled.p`
  font-size: 24px;
  font-weight: bold;

  @media ${breakpoint.tabOnly} {
    font-size: 20px;
  }

  @media ${breakpoint.sp} {
    font-size: 16px;
  }
`;

const QAnswer = styled.p`
  font-size: 20px;

  @media ${breakpoint.tabOnly} {
    font-size: 16px;
  }

  @media ${breakpoint.sp} {
    font-size: 12px;
  }
`;

const QuestionWrap = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  border-bottom: 1px dashed #d0d0d0;
`;
