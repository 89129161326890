// other
import React from 'react';
import styled from 'styled-components';
import MovieBanner from '../../molecules/MovieBanner';
import { breakpoint } from '../../theme';

type MoviePricePlanProps = {
  scrollTo: (id: string) => void;
};

const MoviePricePlan: React.FC<MoviePricePlanProps> = ({ scrollTo }) => {
  return (
    <>
      <MovieBanner />

      <PointTextBox>
        商品を送るだけで<span>簡単</span>に<span>安く</span>
        動画制作できます！
      </PointTextBox>

      <GridboxPlan>
        <GridItemPlan id='SPlan'>
          <div>
            <PlanType>S</PlanType>
            <PlanTypeName>プラン</PlanTypeName>
          </div>
        </GridItemPlan>
        <GridItemPlan id='SDesc'>
          <div>
            <Bold>
              <Number>10動画</Number>
              <Big>10</Big>万
            </Bold>
            <p>
              オススメ商品、売れ筋商材だけ動画にしたい方、お試しで試してみたい方にオススメのプラン
            </p>
          </div>
        </GridItemPlan>
        <GridItemPlan id='MPlan'>
          <div>
            <Popular>Popular</Popular>
            <PlanTypeM>M</PlanTypeM>
            <PlanTypeName>プラン</PlanTypeName>
          </div>
        </GridItemPlan>
        <GridItemPlan id='MDesc'>
          <div>
            <Bold>
              <Number>50動画</Number>
              <Big>40</Big>万
            </Bold>
            <p>
              YouTubeチャンネルの投稿やオリジナル商品、ロングセラー商品など数多くの商材を動画にしたい方にオススメのプラン
            </p>
          </div>
        </GridItemPlan>
        <GridItemPlan id='LPlan'>
          <div>
            <PlanType>L</PlanType>
            <PlanTypeName>プラン</PlanTypeName>
          </div>
        </GridItemPlan>
        <GridItemPlan id='LDesc'>
          <div>
            <Bold>
              <Number>100動画</Number>
              <Big>70</Big>万
            </Bold>
            <p>
              全ての商品を動画付きに変えていきたい方、コンスタントにYouTubeチャンネルの投稿を行いたい方にオススメのプラン
            </p>
          </div>
        </GridItemPlan>
        <GridItemPlan id='AddOption'>
          <div>
            <PlanTypeName>追加</PlanTypeName>
          </div>
        </GridItemPlan>
        <GridItemPlan id='AddDesc'>
          <div>
            <Bold>1動画~</Bold>
            <SubTitle>時期によって動画を追加されたい方向け</SubTitle>
            <p>
              Lプラン・・・ 7,000円
              <br />
              Mプラン・・・8,000円
              <br />
              Sプラン・・・10,000円
            </p>
          </div>
        </GridItemPlan>
      </GridboxPlan>

      <Caution>
        <li>※全て税別価格です</li>
        <li>※料金は変更する場合がございます</li>
        <li>
          ※その他確認項目
          <ul>
            <li>
              ・動画の長さは30秒以内となります（商品レビューなど長時間の動画も可能です）
            </li>
            <li>・テロップは50文字以内</li>
            <li>・動画の最後にネットショップのロゴを表示</li>
            <li>・修正1回まで無料</li>
          </ul>
        </li>
      </Caution>

      <GridboxPlanOption>
        <GridItemPlanOptionTitle>
          <div>オプション</div>
        </GridItemPlanOptionTitle>
        <GridItemPlanOption>
          <div>お急ぎ制作</div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>
            商品到着後
            <br />
            5日以内に納品します
          </div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>5千円～/本</div>
        </GridItemPlanOption>

        <GridItemPlanOption>
          <div>素材制作</div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>イラスト、アニメーションなど制作いたします</div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>5千円～/本</div>
        </GridItemPlanOption>

        <GridItemPlanOption>
          <div>
            BGM
            <br />
            カスタマイズ
          </div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>選べるBGMの種類が増えます</div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>2千円～/本</div>
        </GridItemPlanOption>

        <GridItemPlanOption>
          <div>テロップ挿入</div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>動画にテロップをいれます</div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>千円～ /1つ</div>
        </GridItemPlanOption>

        <GridItemPlanOption>
          <div>ナレーション</div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>声を吹き込みます</div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>
            １万円～ /<br /> 100文字
          </div>
        </GridItemPlanOption>

        <GridItemPlanOption>
          <div>モデル撮影</div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>
            モデルに商品を使ってもらうなどの方法で撮影します
            <br />
            （別途お見積り）
          </div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>3万円～</div>
        </GridItemPlanOption>

        <GridItemPlanOption>
          <div>
            オーダー
            <br />
            メイドプラン
          </div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>HOW TO動画、PR動画などこだわりたい方むけプラン</div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>5万円～</div>
        </GridItemPlanOption>

        <GridItemPlanOption>
          <div>
            YouTube
            <br />
            チャンネル開設
          </div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>チャンネルカバー画像とアイコン制作、初期設定を行います</div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>10万円～</div>
        </GridItemPlanOption>

        <GridItemPlanOption>
          <div>
            YouTube
            <br />
            運用代行
          </div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>動画の投稿、タグやタイトル設定などの運用を行います</div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>5万円～/月</div>
        </GridItemPlanOption>

        <GridItemPlanOption>
          <div>
            YouTube
            <br />
            コンサル
          </div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>運用に加えチャンネル登録者数を増やすご支援を行います</div>
        </GridItemPlanOption>
        <GridItemPlanOption>
          <div>15万円～/月</div>
        </GridItemPlanOption>
      </GridboxPlanOption>

      <DiscountDesc>
        大幅ディスカウントの
        <br />
        <Highlight>年間契約</Highlight>
        もございます
        <br />
        コンスタントに動画制作を依頼したい方は
        <br />
        ぜひ
        <LinkText onClick={() => scrollTo('requestForm')}>
          お問い合わせ
        </LinkText>
        ください
      </DiscountDesc>
    </>
  );
};

export default MoviePricePlan;

// ------------- theme -------------
const MainColor = '#ff8574';
const ColorYellow = '#ffee57';

const PointTextBox = styled.div`
  max-width: 1000px;
  position: relative;
  margin: 16px auto 0;
  padding: 32px 0px;
  padding-left: 32px;

  background-color: #fff;
  border: 1px solid #000;
  font-size: 26px;
  font-weight: bold;
  text-align: center;

  @media ${breakpoint.tabOnly} {
    margin: 32px 24px;
  }

  @media ${breakpoint.sp} {
    margin: 32px 24px;
    padding: 32px 46px;
  }

  & > span {
    margin: 0 4px;
    padding: 4px 8px;
    background-color: ${ColorYellow};
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-top: 42px solid ${MainColor};
    border-right: 44px solid transparent;
    border-bottom: 42px solid transparent;
    border-left: 44px solid ${MainColor};
  }

  &::after {
    content: 'point';
    color: #fff;
    position: absolute;
    top: 12px;
    left: -2px;
    -webkit-transform: rotate(-42deg);
    transform: rotate(-42deg);
  }

  @media ${breakpoint.sp} {
    font-size: 18px;
    margin: 32px 16px;
    padding: 32px 46px;

    &::before {
      border-top-width: 30px;
      border-right-width: 34px;
      border-bottom-width: 30px;
      border-left-width: 34px;
    }

    &::after {
      top: 10px;
    }
  }
`;

// 動画プラン（撮影 & 編集込み）
const GridboxPlan = styled.div`
  display: grid;
  grid-template-rows: 140px 140px 140px 200px;
  grid-template-columns: 200px 1fr;
  grid-gap: 2px 2px;
  max-width: 1000px;
  margin: 12px auto;

  @media ${breakpoint.tabOnly} {
    margin: 12px;
  }

  @media ${breakpoint.sp} {
    grid-template-rows: 110px 110px 110px 160px;
    grid-template-columns: 105px 1fr;
    margin: 12px;
  }

  grid-template-areas:
    'SPlan SDesc'
    'MPlan MDesc'
    'LPlan LDesc'
    'AddOption AddDesc';

  #SPlan {
    grid-area: SPlan;
    background: rgba(255, 133, 116, 0.2);
    width: 100%;
    height: 140px;
    position: relative;

    @media ${breakpoint.sp} {
      height: 110px;
    }

    & > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      -webkit-transform: translateY(-50%) translateX(-50%);
      text-align: center;
    }
  }

  #SDesc {
    grid-area: SDesc;
    background: rgba(255, 133, 116, 0.1);
    height: 140px;

    @media ${breakpoint.sp} {
      height: 110px;
    }

    & > div {
      margin: 20px;

      @media ${breakpoint.sp} {
        margin: 0 8px 0;
      }
    }
  }

  #MPlan {
    grid-area: MPlan;
    background: rgba(110, 193, 153, 0.2);
    width: 100%;
    height: 140px;
    position: relative;

    @media ${breakpoint.sp} {
      height: 110px;
    }

    & > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      -webkit-transform: translateY(-50%) translateX(-50%);
      text-align: center;
      width: 200px;
      height: 90%;
    }
  }

  #MDesc {
    grid-area: MDesc;
    background: rgba(110, 193, 153, 0.1);
    height: 140px;

    @media ${breakpoint.sp} {
      height: 110px;
    }

    & > div {
      margin: 20px;

      @media ${breakpoint.sp} {
        margin: 0 8px 0;
      }
    }
  }

  #LPlan {
    grid-area: LPlan;
    background: rgba(65, 89, 211, 0.2);
    width: 100%;
    height: 140px;
    position: relative;

    @media ${breakpoint.sp} {
      height: 110px;
    }

    & > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      -webkit-transform: translateY(-50%) translateX(-50%);
      text-align: center;
    }
  }

  #LDesc {
    grid-area: LDesc;
    background: rgba(65, 89, 211, 0.1);
    height: 140px;

    @media ${breakpoint.sp} {
      height: 110px;
    }

    & > div {
      margin: 20px;

      @media ${breakpoint.sp} {
        margin: 0 8px 0;
      }
    }
  }

  #AddOption {
    grid-area: AddOption;
    background: rgba(118, 118, 118, 0.2);
    width: 100%;
    height: 200px;
    position: relative;

    @media ${breakpoint.sp} {
      height: 160px;
    }

    & > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      -webkit-transform: translateY(-50%) translateX(-50%);
      text-align: center;
    }
  }

  #AddDesc {
    grid-area: AddDesc;
    background: rgba(118, 118, 118, 0.1);
    height: 200px;

    @media ${breakpoint.sp} {
      height: 160px;
    }

    & > div {
      margin: 20px;

      @media ${breakpoint.sp} {
        margin: 12px 8px 0;
      }
    }
  }
`;

const Popular: any = styled.span`
  padding: 4px 40px;
  background: #ffee57;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;

  @media ${breakpoint.sp} {
    padding: 4px 20px;
    font-weight: bold;
  }
`;

const Bold: any = styled.span`
  font-size: 20px;
  font-weight: bold;

  @media ${breakpoint.sp} {
    font-size: 18px;
  }
`;

const Big: any = styled.span`
  font-weight: normal;
  font-size: 42px;

  @media ${breakpoint.sp} {
    font-size: 36px;
  }
`;

const Number: any = styled.span`
  display: inline-block;
  width: 90px;
`;

const SubTitle: any = styled.p`
  margin: 8px 0;
`;

const GridItemPlan: any = styled.div`
  @media ${breakpoint.sp} {
    font-size: 12px;
  }
`;

const PlanType: any = styled.span`
  font-size: 46px;
  line-height: 36px;
  font-weight: bold;
  display: block;

  @media ${breakpoint.sp} {
    font-size: 36px;
    font-weight: normal;
  }
`;

const PlanTypeM: any = styled(PlanType)`
  margin-top: 20px;

  @media ${breakpoint.sp} {
    margin-top: 10px;
  }
`;

const PlanTypeName: any = styled.span`
  font-size: 20px;
  font-weight: bold;

  @media ${breakpoint.sp} {
    font-size: 16px;
  }
`;

// 説明
const Caution: any = styled.ul`
  max-width: 1000px;
  margin: 12px auto;

  @media ${breakpoint.tabOnly} {
    margin: 12px;
  }

  @media ${breakpoint.sp} {
    font-size: 12px;
    margin: 12px;
  }
`;

// 動画プランオプション
const GridboxPlanOption = styled.div`
  display: grid;
  grid-template-rows: 66px 85px 85px 85px 85px 85px 85px 85px 85px 85px 85px;
  grid-template-columns: 1fr 2fr 1fr;
  grid-row-gap: 2px;
  max-width: 1000px;
  margin: 0 auto;

  @media ${breakpoint.tabOnly} {
    margin: 0 12px;
  }

  @media ${breakpoint.sp} {
    grid-template-rows: 36px 60px 50px 50px 50px 70px 70px 60px 60px 60px 60px;
    grid-template-columns: 3fr 5fr 2fr;
    margin: 0 12px;
    font-size: 12px;
  }
`;

const GridItemPlanOption = styled.div`
  /* センタリングの指定 */
  &:nth-child(3n - 1) {
    position: relative;

    & > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      -webkit-transform: translateY(-50%) translateX(-50%);
      width: 100%;
      text-align: center;
    }
  }

  &:nth-child(3n) {
    position: relative;

    & > div {
      position: absolute;
      top: 50%;
      left: 0%;
      transform: translateY(-50%) translateX(0%);
      -webkit-transform: translateY(-50%) translateX(0%);
      margin-left: 20px;

      @media ${breakpoint.sp} {
        margin-left: 8px;
      }
    }
  }

  &:nth-child(3n + 1) {
    position: relative;
    border-left: 0.5px dashed #868686;

    & > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      -webkit-transform: translateY(-50%) translateX(-50%);
      width: 100%;
      text-align: center;
    }
  }

  /* 色の指定 */
  &:nth-child(6n + 2) {
    background: rgba(110, 193, 153, 0.2);
  }
  &:nth-child(6n + 3) {
    background: rgba(243, 243, 243, 0.5);
  }
  &:nth-child(6n + 4) {
    background: rgba(243, 243, 243, 0.5);
  }

  &:nth-child(6n + 5) {
    background: rgba(110, 193, 153, 0.3);
  }
  &:nth-child(6n + 6) {
    background: rgba(243, 243, 243, 1);
  }
  &:nth-child(6n + 7) {
    background: rgba(243, 243, 243, 1);
  }
`;

const GridItemPlanOptionTitle = styled.div`
  grid-column: 1 / span 3;
  background: #6ec199;
  position: relative;

  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);

    color: #fff;
    font-size: 26px;
    font-weight: bold;

    @media ${breakpoint.sp} {
      font-size: 18px;
    }
  }
`;

const DiscountDesc = styled.p`
  max-width: 1000px;
  margin: 36px auto 0;
  text-align: center;
  font-size: 30px;
  font-weight: bold;

  @media ${breakpoint.sp} {
    font-size: 16px;
    line-height: 26px;
  }
`;

const Highlight = styled.span`
  background: #ff8574;
  color: #fff;
  padding: 8px;
  border-radius: 23.5px;
`;

const LinkText = styled.span`
  text-decoration: underline;

  :hover,
  :active,
  :focus {
    opacity: 0.7;
    cursor: pointer;
  }
`;
