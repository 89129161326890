// other
import useMedia from 'app/src/customHooks/userMedia';
import React from 'react';
import styled from 'styled-components';
import LazyLoadImage from '../../atoms/LazyLoadImage';
import { breakpoint } from '../../theme';

const MovieBanner = () => {
  const isMobile = useMedia('(max-width: 767px)');
  const url = isMobile
    ? 'https://static.croccha.com/company/ec-movie/banner_discount_sp_01.png'
    : 'https://static.croccha.com/company/ec-movie/banner_discount_pc_01.png?w=600';

  return (
    <BannerDiscountWrap>
      <BannerDiscount src={url} alt='リリースキャンペーンにつき割引中' />
    </BannerDiscountWrap>
  );
};

export default MovieBanner;

const BannerDiscountWrap = styled.div`
  text-align: center;
`;

const BannerDiscount = styled(LazyLoadImage)`
  display: inline-block;

  height: 150px;

  margin: 32px auto 0;
  object-fit: cover;

  @media ${breakpoint.sp} {
    height: 90%;
  }
`;
