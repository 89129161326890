import React from 'react';
import BaseLayoutTemplateECMovie from '../components/environments/BaseLayoutTemplateECMovie';
import styled, { css } from 'styled-components';
import { breakpoint } from '../components/theme';
import {
  Specialized,
  PriceBtn,
  RequestBtn,
  ProblemMsg,
  ProblemImg,
  EcMovieParlorLogo,
  CROCCHA_API,
  BagImage,
  BagImageSlideShow,
  PosterSneaker,
  PosterEmbossedHeater,
  PosterDriedFruits,
  PosterYellowBag,
  PosterSneakerMP4,
  PosterEmbossedHeaterMP4,
  PosterDriedFruitsMP4,
  PosterYellowBagMP4,
  TopMP4,
} from '../utils';
import useMedia from '../customHooks/userMedia';
import HeadBase from '../components/molecules/HeadBase';
import LazyLoadImage from '../components/atoms/LazyLoadImage';
import fileicon from '../../static/images/fileicon.svg';
import movieLogo from '../../static/images/movie-logo.png';

import ECMovieForm from '../components/organisms/ECMovieForm';

import { Element, scroller } from 'react-scroll';
import MoviePricePlan from 'app/src/components/organisms/MoviePricePlan';
import MovieQuestion from 'app/src/components/organisms/MovieQuestion';
import MovieSteps from 'app/src/components/organisms/MovieSteps';
import MovieSampleMovie from 'app/src/components/organisms/MovieSampleMovie';
import MovieRequestButton from 'app/src/components/molecules/MovieRequestButton';

const ECMovieParlorPage = () => {
  const isMobile = useMedia('(max-width: 767px)');
  const YouTubesamples = [
    { id: 'bsznvMgT8n0' },
    { id: 'fAuvDA1Y0dA' },
    { id: 'jkFvRYpe0uQ' },
  ];
  const mp4samples = [
    {
      url: PosterSneakerMP4,
      poster: PosterSneaker,
      title: '例1: スニーカーのご紹介',
    },
    {
      url: PosterEmbossedHeaterMP4,
      poster: PosterEmbossedHeater,
      title: '例2: エンボスヒーターの使い方紹介',
    },
    {
      url: PosterDriedFruitsMP4,
      poster: PosterDriedFruits,
      title: '例3: ドライフルーツのご紹介',
    },
  ];

  const scrollTo = (id: string) => {
    scroller.scrollTo(id, {
      spy: true,
      smooth: true,
      offset: -70,
      duration: 500,
    });
  };

  const UserHeatTag = {
    __html: `
    (function(add, cla){window['UserHeatTag']=cla;window[cla]=window[cla]||function(){(window[cla].q=window[cla].q||[]).push(arguments)},window[cla].l=1*new Date();var ul=document.createElement('script');var tag = document.getElementsByTagName('script')[0];ul.async=1;ul.src=add;tag.parentNode.insertBefore(ul,tag);})('//uh.nakanohito.jp/uhj2/uh.js', '_uhtracker');_uhtracker({id:'uh90GcMHuK'});
    `,
  };

  return (
    <BaseLayoutTemplateECMovie>
      <HeadBase
        metas={{
          title: 'ECに特化した動画制作が7,000円~ EC動画パーラー',
          description:
            '通販商品に特化した商品動画を制作します！動画で売り上げを伸ばしたいネットショップのオーナー様必見です！',
          openGraph: {
            type: 'article',
            url: `${CROCCHA_API.SELF_DOMAIN}/ec-movie-parlor/`,
            title: 'ECに特化した動画制作が7,000円~ EC動画パーラー',
            description:
              '通販商品に特化した商品動画を制作します！動画で売り上げを伸ばしたいネットショップのオーナー様必見です！',
            images: [
              {
                url: 'https://tryangle-croccha.s3.ap-northeast-1.amazonaws.com/company/ec-movie/ec-movie-ogp_02.png',
                width: 1200,
                height: 630,
                alt: 'ECに特化した動画制作が7,000円~ EC動画パーラー',
              },
            ],
            site_name: 'ECに特化した動画制作が7,000円~ EC動画パーラー',
          },
        }}
      />

      <script
        type='text/javascript'
        dangerouslySetInnerHTML={UserHeatTag}
      ></script>

      {/* タイトル */}
      <Section>
        <Inner>
          <h1>
            <MainVideo src={TopMP4} muted playsInline autoPlay loop></MainVideo>
            <MainCaption
              src={Specialized}
              alt='通販商品に特化した商品動画を制作します！動画で売り上げを伸ばしたいネットショップのオーナー様必見です！'
            />
          </h1>
        </Inner>
      </Section>

      {/* 動画サンプル */}
      <Section>
        <Sample>
          <SampleTitle>動画サンプル</SampleTitle>
          <SampleList>
            {/* {youtubesamples.map((youtube) => (
              <SampleListItem>
                <IframeWrap
                  id='ytplayer'
                  src={`https://www.youtube.com/embed/${youtube.id}?controls=1&modestbranding=1&rel=0&origin=http://example.com&listType=user_uploads`}
                />
              </SampleListItem>
            ))} */}

            {mp4samples.map((movie) => (
              <li>
                <SampleVideo
                  src={movie.url}
                  poster={movie.poster}
                  muted
                  playsInline
                  controls
                  preload='metadata'
                ></SampleVideo>
                <p>{movie.title}</p>
              </li>
            ))}
          </SampleList>
        </Sample>
        <ButtonWrap onClick={() => scrollTo('requestForm')}>
          <LazyLoadImage src={RequestBtn} alt='無料相談はこちら' />
        </ButtonWrap>
        <ButtonWrap onClick={() => scrollTo('moviePricePlan')}>
          <LazyLoadImage src={PriceBtn} alt='価格表はこちら' />
        </ButtonWrap>
      </Section>

      {/* こんな悩みありませんか？ */}
      <Section>
        <Problem>
          <h2>
            <LazyLoadImage src={ProblemMsg} alt='こんな悩みありませんか？' />
          </h2>
          <LazyLoadImage src={ProblemImg} alt='売り上げを上げたい' />
        </Problem>
        <Point>
          <Inner>
            <PointInner>
              <LogoImage src={EcMovieParlorLogo} alt='EC動画パーラー' />
              <span>なら...</span>
            </PointInner>
            <PointText>EC動画専門</PointText>
            <PointText>ネットショップ開発のノウハウあり</PointText>
            <PointTextBox>
              商品を送るだけで<span>簡単</span>に<span>安く</span>
              動画制作できます！
            </PointTextBox>
          </Inner>
        </Point>
      </Section>

      {/* よくあるボトルネック */}
      <Section>
        <Bottleneck>
          <TitleBalloon>
            <TitleIcon />
            <h3>
              ネットショップでよくある
              <br />
              <span>ボトルネック</span>
            </h3>
          </TitleBalloon>
          <Inner>
            <BottleneckText>
              やることが多く
              <br />
              全てを
              <span>
                自社で解決することが
                <br />
                なかなか難しい
              </span>
            </BottleneckText>
            <ol>
              <li>
                <span>商品企画・仕入れ</span>
                自社のお客様に対して魅力的な商品の企画、仕入れを行う必要がある部分
              </li>
              <li>
                <span>撮影・動画・バナー</span>
                お客様に商品の魅力を伝える大事な部分だが、マンパワー不足になってしまいがち
              </li>
              <li>
                <span>販売・広告・SNS</span>
                売れなければコストになってしまう。SEO対策があっているのか、広告に適切な費用をかけられているのか、など日々改善していく必要がある部分
              </li>
              <li>
                <span>発送・カスタマー</span>
                カスタマーサポート・物流はお客様と接する部分で、競合のショップも専門会社に委託しているケースが多く差別化が難しい部分
              </li>
            </ol>
            <BottleneckTextBox>
              <span>マンパワー不足</span>になってしまいがちな
              <span>「撮影・動画・バナー」部分の制作を代行します。</span>
              <br />
              EC動画を専門にやっているため、最小限の工数で
              <span>ECに最適な動画</span>を作成できます！
            </BottleneckTextBox>
          </Inner>
        </Bottleneck>
      </Section>

      {/* 売り上げをUPさせる方法 */}
      <Section>
        <Method>
          <TitleBalloon>
            <TitleIcon />
            <h3>
              ネットショップの
              <br />
              <span>売り上げをUPさせる</span>方法
            </h3>
          </TitleBalloon>
          <Inner>
            <MethodText>
              <div>ECの売り上げは...</div>
              <ul>
                <li>アクセス数</li>
                <li>
                  CVR<span>（コンバージョン率）</span>
                </li>
                <li>単価</li>
              </ul>
            </MethodText>
            <MethodList>
              <li>
                <div>
                  動画は<span>SEOにも効果的です！</span>
                  サイトの滞在時間が長くなります。
                </div>
              </li>
              <li>
                <div>
                  製品やサービスについて詳しく知るために96%の人が
                  <span>解説動画</span>
                  を見たことがあります。
                  <SmallText>※1</SmallText>
                </div>
              </li>
              <li>
                <div>
                  商品紹介ページに動画をつけたところ、
                  <span>CVRが1.8% 〜8%に改善</span>した例も！返品が24%減！平均
                  <span>20%~40%の売上増加</span>があった事例も！
                  <SmallText>※2</SmallText>
                </div>
              </li>
              <li>
                <div>
                  Zapposでは、動画により商品の売上が
                  <span>6%〜30%UPしました</span>。<SmallText>※3</SmallText>
                </div>
              </li>
              <li>
                <div>
                  YouTubeからのアクセスも期待！YouTubeは7年連続で動画マーケターにとって最も使われているプラ​​ットフォームであり、2021年は
                  <span>マーケターの87%が使用しています</span>。
                  <SmallText>※1</SmallText>
                </div>
              </li>
              {/* <li>
                <div>
                  動画を視聴したユーザーは、そうでないユーザーよりも
                  <span>コンバージョンに至る可能性が15〜20%高くなります</span>。
                  <SmallText>※4</SmallText>
                </div>
              </li> */}
            </MethodList>
            <Cite>
              ※1 wyzowl 2021 https://www.wyzowl.com/video-marketing-statistics/
              <br />
              ※2 Zappos 2010 https://markezine.jp/article/detail/9279
              <br />
              ※3 Zappos 2009
              https://www.businessinsider.com/zappos-sells-6-30-more-merchandise-when-accompanied-by-video-demos-2009-12
              {/* <br />
              ※4 wistia 2021
              https://wistia.com/learn/marketing/retargeting-with-video */}
            </Cite>
          </Inner>
        </Method>
      </Section>

      {/* 動画をつけることで */}
      <Section>
        <SecondMethod>
          <TitleBalloon>
            <TitleIcon />
            <h3>
              動画をつけることで
              <br />
              <span>ECの売り上げUP</span>に繋がります！
            </h3>
          </TitleBalloon>
          <Inner>
            <SecondMethodText>
              動画だからこそ<YellowText>伝わる！</YellowText>
              <YellowText>売れる！</YellowText>
              <div>単に写真を動画にするだけではダメなんです。。。</div>
            </SecondMethodText>
            <SecondMethodGrid>
              <SecondMethodLeft>
                <SecondMethodImg>
                  <div>静止画</div>
                  <LazyLoadImage src={BagImage} alt='静止画' />
                </SecondMethodImg>
                <SecondMethodImg>
                  <div>スライドショー</div>
                  <LazyLoadImage src={BagImageSlideShow} alt='スライドショー' />
                </SecondMethodImg>
              </SecondMethodLeft>
              <SecondMethodVideoLarge>
                <div>動画</div>
                <SecondMethodVideo
                  src={PosterYellowBagMP4}
                  poster={PosterYellowBag}
                  muted
                  playsInline
                  controls
                  preload='metadata'
                ></SecondMethodVideo>
              </SecondMethodVideoLarge>
            </SecondMethodGrid>
            <SecondMethodText>
              お客様が知りたいのは
              <br />
              <PinkText>写真だけでは伝わらない</PinkText>ところ
            </SecondMethodText>
            <ul>
              <li>大きさ</li>
              <li>使い方</li>
              <li>色</li>
              <li>使用イメージ</li>
              <li>質感</li>
              <li>量</li>
            </ul>
            <ChevronBottom />
            <SecondChevronBottom />
          </Inner>
        </SecondMethod>
      </Section>

      {/* EC動画パーラーなら... */}
      <Section>
        <ThirdMethod>
          <h2>EC動画パーラーなら…</h2>
          <Inner>
            <ThirdMethodPoint>
              <li>
                <ThirdMethodCircle>
                  point<span>1</span>
                </ThirdMethodCircle>
                <LazyLoadImage
                  src='https://static.croccha.com/company/ec-movie/static_image_01.jpg'
                  alt='商品の裏側まで撮影します'
                />
                <ThirdMethodText>
                  <span>商品の裏側</span>まで撮影します
                </ThirdMethodText>
              </li>
              <li>
                <ThirdMethodCircle>
                  point<span>2</span>
                </ThirdMethodCircle>
                <LazyLoadImage
                  src='https://static.croccha.com/company/ec-movie/static_image_02.jpg'
                  alt='一つ一つ手にとって撮影します'
                />
                <ThirdMethodText>
                  大きさがわかるように一つ一つ<span>手にとって撮影</span>します
                </ThirdMethodText>
              </li>
            </ThirdMethodPoint>
            <BalloonList>
              <li>
                手で持つから
                <br />
                大きさが
                <br />
                わかる
              </li>
              <li>
                テロップが
                <br />
                つけられる
                <br />
                （オプション）
              </li>
              <li>
                使い方も
                <br />
                説明
              </li>
            </BalloonList>
            <ThirdMethodWrap>
              <ThirdMethodVideo
                src={PosterYellowBagMP4}
                poster={PosterYellowBag}
                muted
                playsInline
                width='600px'
                controls
                preload='metadata'
              ></ThirdMethodVideo>
              <div>
                お客様により詳しく商品イメージを
                <br />
                伝えられるので、<span>信頼性 UP！</span>
              </div>
            </ThirdMethodWrap>
          </Inner>
        </ThirdMethod>
      </Section>

      {/* サンプル動画一覧 */}
      <Section>
        <SampleMovieBalloon>
          <h2>
            <LogoImg src={movieLogo}></LogoImg>
            <p>サンプル動画一覧</p>
          </h2>
        </SampleMovieBalloon>

        <MovieSampleMovie></MovieSampleMovie>
      </Section>

      {/* 動画プラン（撮影 & 編集込み） */}
      <Section>
        <Element name='moviePricePlan'>
          <GrayTitle>
            <p>動画プラン（撮影 & 編集込み）</p>
          </GrayTitle>

          <MoviePricePlan scrollTo={scrollTo} />
        </Element>
      </Section>

      {/* ご依頼から納品までの流れ */}
      <Section>
        <GrayTitle>
          <p>ご依頼から納品までの流れ</p>
        </GrayTitle>

        <MovieSteps />
      </Section>

      {/* よくお問い合わせいただくご質問 */}
      <Section>
        <GrayTitle>
          <p>よくお問い合わせいただくご質問</p>
        </GrayTitle>

        <MovieQuestion />
      </Section>

      {/* 無料相談はこちら */}
      <Section>
        <Element name='requestForm'>
          <RequestInner>
            <GreenTitle>
              <img
                src={fileicon}
                alt='無料相談はこちら'
                width='32'
                height='32'
              />
              <p>無料相談はこちら</p>
            </GreenTitle>

            {/* フォーム */}
            <ECMovieForm></ECMovieForm>
          </RequestInner>
        </Element>
      </Section>

      <MovieRequestButton scrollTo={scrollTo} />
    </BaseLayoutTemplateECMovie>
  );
};

export default ECMovieParlorPage;

// ------------- theme -------------
export const MainColor = '#ff8574';
export const ColorYellow = '#ffee57';
export const ColorBlue = '#4159d3';
export const ColorGreen = '#6ec199';
export const BorderColor = '#d0d0d0';
export const BgColor = '#f5f5f5';

export const HeadingLarge = css`
  font-size: 32px;
  font-weight: bold;
  @media ${breakpoint.sp} {
    font-size: 20px;
  }
`;

export const Triangle = css`
  content: '';
  position: absolute;
  top: 6px;
  border-right: 12px solid transparent;
  border-bottom: 20px solid currentColor;
  border-left: 12px solid transparent;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
`;

const TitleBalloon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  margin-bottom: 40px;
  background-color: #ffa99d;

  &::after {
    content: '';
    position: absolute;
    left: calc(50% - 6px);
    bottom: -15px;
    border-right: 12px solid transparent;
    border-bottom: 16px solid #ffa99d;
    border-left: 12px solid transparent;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  h3 {
    font-size: 32px;

    & span {
      color: #fff;
      -webkit-text-stroke: 1px #000;
      text-stroke: 1px #000;
    }
  }

  @media ${breakpoint.sp} {
    height: auto;
    padding: 16px;

    h3 {
      font-size: 20px;
    }
  }
`;

// ------------- 動画サイズ -------------
export const VideoSizeMax = css`
  width: 1000px;
  height: calc(1000px * 0.562);
  @media ${breakpoint.sp} {
    width: 100vw;
    height: calc(100vw * 0.562);
  }
  @media ${breakpoint.tabOnly} {
    width: 100vw;
    height: calc(100vw * 0.562);
  }
`;

const VideoSize3col = css`
  width: calc(1000px / 3 - 24px);
  height: calc((1000px / 3 - 24px) * 0.562);

  @media ${breakpoint.sp} {
    width: calc(100vw / 3 - 24px);
    height: calc((100vw / 3 - 24px) * 0.562);
  }

  @media ${breakpoint.tabOnly} {
    width: calc(100vw / 3 - 24px);
    height: calc((100vw / 3 - 24px) * 0.562);
  }
`;

export const VideoSize = css`
  width: 700px;
  height: calc(700px * 0.562);

  @media ${breakpoint.sp} {
    width: calc(100vw - 48px);
    height: calc((100vw - 48px) * 0.562);
  }

  @media ${breakpoint.tabOnly} {
    width: calc(100vw - 48px);
    height: calc((100vw - 48px) * 0.562);
  }
`;

// ------------- セクション -------------
const Section = styled.section``;

const Inner = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;

// ------------- メイン動画 -------------
const MainVideo = styled.video`
  ${VideoSizeMax};
`;

const MainCaption = styled(LazyLoadImage)`
  display: flex;
  justify-content: center;
  margin: 32px auto;

  @media ${breakpoint.sp} {
    margin: 0 auto;
  }
`;

// 無料サンプル動画
const Sample = styled.div`
  background-color: ${BgColor};
  margin: 32px auto;
  padding: 24px;

  @media ${breakpoint.sp} {
    padding: 24px 12px;
  }
`;

const SampleTitle = styled.h2`
  position: relative;
  width: 6em;
  margin: 0 auto;
  font-size: 24px;

  &::before,
  &:after {
    ${Triangle}
    border-bottom-color: ${BorderColor};
  }

  &::before {
    left: -32px;
  }

  &:after {
    right: -26px;
  }

  @media ${breakpoint.sp} {
    font-size: 18px;
    padding-top: 5px;
  }
`;

const SampleMovieBalloon = styled(TitleBalloon)`
  height: auto;
  max-width: 1000px;
  margin: 0 auto 40px;
  padding: 16px 0;
  background: ${MainColor};
  border-radius: 4px;

  &::after {
    border-bottom-color: ${MainColor};
  }

  h2 {
    display: flex;
    display: -webkit-flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;

    & > p {
      ${HeadingLarge}
      line-height: 44px;
      margin-left: 9px;
      color: #fff;
    }
  }

  @media ${breakpoint.tabOnly} {
    margin: 0 16px 40px;
  }

  @media ${breakpoint.sp} {
    margin: 0 16px 40px;
  }
`;

const LogoImg = styled.img`
  width: 70px;
  height: 100%;

  @media ${breakpoint.sp} {
    width: 25px;
  }
`;

// 動画プラン
const GreenTitle = styled.h2`
  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;

  margin: 80px auto 0;
  padding: 16px 0;
  background: #6ec199;
  border-radius: 4px;

  @media ${breakpoint.sp} {
    margin: 40px auto 0;
  }

  & > p {
    font-size: 32px;
    margin-left: 9px;
    color: #fff;

    &::before {
      left: -34px;
    }

    &::after {
      right: -28px;
    }

    @media ${breakpoint.sp} {
      font-size: 18px;
    }
  }
`;

const RequestInner = styled.div`
  background: #f3f3f3;
`;

const SampleList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  width: 100%;
  max-width: 1000px;
  margin: 24px auto;

  @media ${breakpoint.tabOnly} {
    max-width: 100vw;
    grid-gap: 12px;
  }

  @media ${breakpoint.sp} {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 12px 8px;
    margin-bottom: 0;

    li {
      width: calc(50% - 8px);
    }
  }
`;

const ButtonWrap = styled.div`
  margin: 16px auto;
  text-align: center;

  & a {
    display: inline-block;
  }

  :hover,
  :active,
  :focus {
    opacity: 0.7;
    color: #fff;
    cursor: pointer;
  }

  @media ${breakpoint.sp} {
    margin: 16px;
  }
`;

const SampleVideo = styled.video`
  @media ${breakpoint.tab} {
    ${VideoSize3col};
  }

  @media ${breakpoint.sp} {
    width: 100%;
  }
`;

// こんな悩みありませんか？
const Problem = styled.div`
  position: relative;
  margin-bottom: 5vw;
  padding: 100px 0 40px;
  background-color: #9daaf1;
  text-align: center;

  @media ${breakpoint.tabOnly} {
    padding: 100px 24px 40px;
  }

  @media ${breakpoint.sp} {
    margin-top: 40px;
    padding: 60px 0 40px;
  }

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    border-right: 50vw solid transparent;
    border-bottom: 5vw solid #fff;
    border-left: 50vw solid transparent;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: calc(-5vw + 1px);
    right: 0;
    border-right: 50vw solid transparent;
    border-bottom: 5vw solid #9daaf1;
    border-left: 50vw solid transparent;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  @media ${breakpoint.sp} {
    > h2,
    > div {
      margin: 0 16px;
    }
  }
`;

const Point = styled.div`
  margin: 120px auto 80px;

  @media ${breakpoint.sp} {
    margin: 40px auto;
  }
`;

const PointInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  padding-bottom: 20px;

  &::after {
    content: '';
    position: absolute;
    top: 68px;
    bottom: 0;
    background-image: linear-gradient(
      to right,
      ${MainColor},
      ${MainColor} 3px,
      transparent 3px,
      transparent 8px
    );
    background-size: 6px 3px;
    background-repeat: repeat-x;
    width: 40%;
    height: 3px;

    @media ${breakpoint.tabOnly} {
      width: 60%;
    }
  }

  span {
    ${HeadingLarge}
    margin-left: 12px;
    color: #fff;
    -webkit-text-stroke: 1px #000;
    text-stroke: 1px #000;
  }

  @media ${breakpoint.sp} {
    display: grid;
    grid-template-columns: 66% 34%;
    margin: 0 16px 16px;
    text-align: center;

    &::after {
      top: 80%;
      width: 100%;
    }

    span {
      margin-left: 0;
      font-size: 24px;
      text-align: left;
    }
  }
`;

const LogoImage = styled(LazyLoadImage)`
  width: 100%;
  height: auto;

  @media ${breakpoint.sp} {
    width: 80%;
  }
`;

const PointText = styled.div`
  ${HeadingLarge}
  position: relative;
  width: 60%;
  margin: 0 auto 16px;
  padding-left: 60px;
  font-weight: bold;

  @media ${breakpoint.tabOnly} {
    width: 80%;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: ${MainColor};
    color: #fff;
  }

  &::before {
    left: 0;
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }

  &::after {
    top: 12px;
    left: 12px;
    width: 18px;
    height: 12px;
    border-bottom: solid 3px currentColor;
    border-left: solid 3px currentColor;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  @media ${breakpoint.sp} {
    width: 80%;
    margin: 0 auto 16px;
    padding-left: 32px;

    &::before {
      width: 24px;
      height: 24px;
    }

    &::after {
      top: 6px;
      left: 6px;
      width: 12px;
      height: 8px;
    }
  }
`;

const PointTextBox = styled.div`
  position: relative;
  margin-top: 32px;
  padding: 32px 0;
  background-color: #fff;
  border: 1px solid #000;
  font-size: 26px;
  font-weight: bold;
  text-align: center;

  @media ${breakpoint.tabOnly} {
    margin: 32px 24px;
  }

  & > span {
    margin: 0 4px;
    padding: 4px 8px;
    background-color: ${ColorYellow};
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-top: 42px solid ${MainColor};
    border-right: 44px solid transparent;
    border-bottom: 42px solid transparent;
    border-left: 44px solid ${MainColor};
  }

  &::after {
    content: 'point';
    color: #fff;
    position: absolute;
    top: 12px;
    left: -2px;
    -webkit-transform: rotate(-42deg);
    transform: rotate(-42deg);
  }

  @media ${breakpoint.sp} {
    font-size: 18px;
    margin: 32px 16px;
    padding: 32px 46px;

    &::before {
      border-top-width: 30px;
      border-right-width: 34px;
      border-bottom-width: 30px;
      border-left-width: 34px;
    }

    &::after {
      top: 10px;
    }
  }
`;

// よくあるボトルネック
const TitleIcon = styled.div`
  position: relative;
  width: 52px;
  height: 52px;
  margin-right: 16px;

  &::before {
    content: '';
    position: absolute;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    border: 1px solid #000;
    background-color: #fff;
  }

  &::after {
    content: '';
    position: absolute;
    top: 16px;
    left: 18px;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #000;
    border-left: 10px solid transparent;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  @media ${breakpoint.sp} {
    width: 28px;
    height: 28px;

    &::before {
      width: 28px;
      height: 28px;
    }

    &::after {
      top: 8px;
      left: 10px;
      border-right-width: 6px;
      border-bottom-width: 12px;
      border-left-width: 6px;
    }
  }
`;

const Bottleneck = styled.div`
  padding-bottom: 80px;
  background-color: ${BgColor};

  @media ${breakpoint.sp} {
    padding-bottom: 50px;
  }

  ol {
    width: 90%;
    margin: 0 auto;

    li {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 140px;
      margin-bottom: 60px;
      background-color: #fff;
      border: 1px solid ${BorderColor};
      padding: 0 16px;

      &::before {
        ${HeadingLarge}
        content: '';
        position: absolute;
        top: -20px;
        left: -20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${BorderColor};
        width: 85px;
        height: 85px;
        border-radius: 50%;
        color: #fff;
        -webkit-text-stroke: 1px #000;
        text-stroke: 1px #000;
      }

      &:first-child::before {
        content: '01';
      }

      &:nth-child(2) {
        border: 4px solid ${MainColor};
        &::before {
          content: '02';
          background-color: ${MainColor};
        }
      }

      &:nth-child(3)::before {
        content: '03';
      }

      &:nth-child(4)::before {
        content: '04';
      }

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        left: calc(50% - 12px);
        bottom: -34px;
        width: 24px;
        height: 24px;
        border-bottom: solid 1px currentColor;
        border-right: solid 1px currentColor;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      span {
        ${HeadingLarge}
        margin-bottom: 10px;
      }

      @media ${breakpoint.sp} {
        margin-bottom: 50px;
        padding: 0 12px;
        font-size: 14px;

        &::before {
          width: 55px;
          height: 55px;
        }
      }
    }
  }
`;

const BottleneckText = styled.div`
  position: relative;
  width: 100%;
  max-width: 695px;
  margin: 0 auto 40px;
  ${HeadingLarge}
  text-align: center;

  @media ${breakpoint.sp} {
    width: 85%;
  }

  &::before,
  &::after {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
  }

  &::before {
    border-left: solid 2px #000000;
    border-top: solid 2px #000000;
    top: 0;
    left: 0;
  }

  &::after {
    border-right: solid 2px #000000;
    border-bottom: solid 2px #000000;
    bottom: 0;
    right: 0;
  }

  span {
    color: ${MainColor};
  }
`;

const BottleneckTextBox = styled(PointTextBox)`
  font-weight: normal;
  padding: 32px 94px;
  text-align: left;

  @media ${breakpoint.sp} {
    margin: 0 16px;
    padding: 32px 40px;
  }

  span {
    margin: 0;
    padding: 0;
    background-color: transparent;
    font-weight: bold;
  }
`;

// 売り上げをUPさせる方法
const Method = styled.div`
  margin-bottom: 80px;

  @media ${breakpoint.sp} {
    margin-bottom: 40px;
  }
`;

const MethodText = styled.div`
  ${HeadingLarge}
  text-align: center;

  ul {
    display: flex;
    justify-content: center;
    margin: 16px auto 40px;

    li {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 190px;
      height: 190px;
      border-radius: 50%;
      color: #fff;

      &:first-child {
        background-color: ${MainColor};
      }

      &:nth-child(2) {
        flex-direction: column;
        margin: 0 50px;
        background-color: ${ColorGreen};
      }

      &:nth-child(3) {
        background-color: ${ColorBlue};
      }

      span {
        font-size: 18px;
      }

      &:not(:last-child)::before,
      &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -40px;
        width: 30px;
        height: 2px;
        background-color: #000;
      }

      &:not(:last-child)::before {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:not(:last-child)::after {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    @media ${breakpoint.tab} {
      display: flex;
    }

    @media ${breakpoint.sp} {
      display: grid;
      grid-template-columns: 100px 160px 100px;
      justify-items: center;
      align-items: center;
      margin: 16px 16px 40px;

      li {
        width: 100px;
        height: 100px;
        font-size: 18px;

        &:not(:last-child)::before,
        &:not(:last-child)::after {
          width: 20px;
          right: -26px;
        }

        &:nth-child(2) {
          margin: 0 30px;
        }

        span {
          font-size: 12px;
        }
      }
    }
  }
`;

const MethodList = styled.ul`
  border-top: 1px dashed ${BorderColor};

  @media ${breakpoint.tabOnly} {
    margin: 0 24px;
  }

  li {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 24px 16px 60px;
    border-bottom: 1px dashed ${BorderColor};
    font-size: 18px;

    &::before {
      content: '';
      position: absolute;
      left: 16px;
      width: 24px;
      height: 24px;
      background-color: ${ColorGreen};
      border-radius: 50%;
    }

    &::after {
      content: '';
      color: #fff;
      position: absolute;
      left: 22px;
      width: 12px;
      height: 8px;
      margin-bottom: 4px;
      border-bottom: solid 3px currentColor;
      border-left: solid 3px currentColor;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    span {
      color: ${MainColor};
      font-size: 22px;
      font-weight: bold;

      @media ${breakpoint.sp} {
        font-size: 18px;
      }
    }
  }
`;

const SmallText = styled.div`
  display: inline;
  font-size: 13px;
`;

const Cite = styled.div`
  margin-top: 16px;
  font-size: 10px;
  color: #999;

  @media ${breakpoint.tabOnly} {
    margin: 16px 24px;
  }

  @media ${breakpoint.sp} {
    margin: 16px;
  }
`;

// 動画をつけることで
const SecondMethod = styled.div`
  margin-bottom: 40px;

  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-bottom: 16px;

    li {
      padding: 16px;
      border: 1px solid ${BorderColor};
      border-radius: 12px;
      font-size: 26px;
      font-weight: bold;
      text-align: center;

      &:first-child,
      &:last-child {
        color: ${MainColor};
      }

      &:nth-child(2),
      &:nth-child(4) {
        color: ${ColorBlue};
      }

      &:nth-child(3),
      &:nth-child(5) {
        color: ${ColorGreen};
      }
    }

    @media ${breakpoint.tabOnly} {
      margin: 0 16px 16px;
    }

    @media ${breakpoint.sp} {
      grid-gap: 8px 4px;
      margin: 0 16px 16px;

      li {
        font-size: 16px;
        padding: 8px 0;
      }
    }
  }
`;

const SecondMethodText = styled.div`
  margin-bottom: 24px;
  font-size: 26px;
  font-weight: bold;
  text-align: center;

  div {
    margin-top: 16px;
    font-size: 18px;
    font-weight: normal;
  }

  @media ${breakpoint.sp} {
    font-size: 20px;

    div {
      font-size: 14px;
    }
  }
`;

const YellowText = styled.span`
  margin: 0 4px;
  padding: 4px 8px;
  background-color: ${ColorYellow};
`;

const PinkText = styled.span`
  color: ${MainColor};
`;

const SecondMethodGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 100px;
  margin-top: 32px;

  @media ${breakpoint.tabOnly} {
    grid-gap: 70px;
    margin: 32px 16px 0;
  }

  @media ${breakpoint.sp} {
    grid-gap: 30px;
    margin: 32px 16px 0;
  }
`;

const SecondMethodLeft = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  &::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 60px;
    border-right: solid 3px #999;
    border-bottom: solid 3px #999;
    top: calc(50% - 45px);
    right: -100px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);

    @media ${breakpoint.tabOnly} {
      width: 40px;
      height: 40px;
      top: calc(50% - 40px);
      right: -70px;
    }

    @media ${breakpoint.sp} {
      width: 24px;
      height: 24px;
      top: calc(50% - 24px);
      right: -34px;
    }
  }
`;

const SecondMethodImg = styled.div`
  margin-bottom: 32px;

  div {
    margin-bottom: 4px;
    padding: 10px 0;
    background-color: #999;
    color: #fff;
    font-size: 26px;
    font-weight: bold;
    text-align: center;

    @media ${breakpoint.sp} {
      font-size: 14px;
      padding: 6px 0;
    }
  }

  img {
    background-color: #ddd;
    display: block;
    width: calc((1000px - 100px) / 3);
    height: calc((1000px - 100px) / 3 * 0.562);

    @media ${breakpoint.tabOnly} {
      width: calc((100vw - 70px - 32px) / 3);
      height: calc((100vw - 70px - 32px) / 3 * 0.562);
    }

    @media ${breakpoint.sp} {
      width: calc((100vw - 24px - 32px) / 3);
      height: calc((100vw - 24px - 32px) / 3 * 0.562);
    }
  }
`;

const SecondMethodVideoLarge = styled(SecondMethodImg)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  div {
    background-color: ${MainColor};
  }
`;

const SecondMethodVideo = styled.video`
  width: calc((1000px - 100px) / 3 * 2);
  height: calc((1000px - 100px) / 3 * 2 * 0.562);

  @media ${breakpoint.tabOnly} {
    width: calc((100vw - 70px - 32px) / 3 * 2);
    height: calc((100vw - 70px - 32px) / 3 * 2 * 0.562);
  }

  @media ${breakpoint.sp} {
    width: calc((100vw - 24px - 32px) / 3 * 2);
    height: calc((100vw - 24px - 32px) / 3 * 2 * 0.562);
  }
`;

const ChevronBottom = styled.span`
  position: relative;
  display: block;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    border-right: solid 3px #999;
    border-bottom: solid 3px #999;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const SecondChevronBottom = styled(ChevronBottom)`
  margin-bottom: 120px;

  &::after {
    top: 24px;
  }
`;

// EC動画パーラーなら（緑）
const ThirdMethod = styled.div`
  margin-bottom: 40px;
  padding: 60px 0;
  background-color: ${ColorGreen};

  h2 {
    position: relative;
    margin-bottom: 60px;
    font-size: 46px;
    font-weight: bold;
    color: #fff;
    -webkit-text-stroke: 1px #000;
    text-stroke: 1px #000;
    text-align: center;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      display: block;
      width: 60px;
      border: 1px solid #fff;
    }

    &::before {
      left: 20%;
      -webkit-transform: rotate(55deg);
      transform: rotate(55deg);
    }

    &::after {
      right: 20%;
      -webkit-transform: rotate(55deg);
      transform: rotate(-55deg);
    }
  }

  @media ${breakpoint.tabOnly} {
    h2 {
      &::before {
        left: 10%;
      }
      &::after {
        right: 10%;
      }
    }
  }

  @media ${breakpoint.sp} {
    padding: 40px 0;

    h2 {
      margin-bottom: 40px;
      font-size: 26px;

      &::before,
      &::after {
        width: 40px;
      }

      &::before {
        left: 8px;
      }

      &::after {
        right: 8px;
      }
    }
  }
`;

const ThirdMethodPoint = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px;

  li {
    position: relative;

    img {
      display: block;
      width: calc((1000px - 60px) / 2);
      height: calc((1000px - 60px) / 2 * 0.562);
      background-color: #ddd;
    }
  }

  @media ${breakpoint.tabOnly} {
    grid-gap: 24px;
    margin: 0 24px;

    li {
      img {
        width: calc((100vw - 24px - 48px) / 2);
        height: calc((100vw - 24px - 48px) / 2 * 0.562);
      }
    }
  }

  @media ${breakpoint.sp} {
    grid-gap: 12px;
    margin: 0 12px;

    li {
      img {
        width: calc((100vw - 24px - 12px) / 2);
        height: calc((100vw - 24px - 12px) / 2 * 0.562);
      }
    }
  }
`;

const ThirdMethodText = styled.div`
  margin-top: 16px;
  color: #fff;
  font-size: 24px;
  font-weight: bold;

  span {
    color: ${ColorYellow};
  }

  @media ${breakpoint.sp} {
    margin-top: 8px;
    font-size: 16px;
  }
`;

const ThirdMethodCircle = styled.div`
  position: absolute;
  top: -32px;
  left: -32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 110px;
  background-color: ${ColorYellow};
  border-radius: 50%;
  font-size: 26px;
  font-weight: bold;
  line-height: 1em;

  span {
    font-size: 46px;
    line-height: 1em;
  }

  @media ${breakpoint.tabOnly} {
    transform: scale(0.8);
  }

  @media ${breakpoint.sp} {
    top: -42px;
    transform: scale(0.6);
  }
`;

const BalloonList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  margin: 60px auto;

  li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
    background: #fff;
    border-radius: 10px;
    color: ${ColorBlue};
    font-size: 22px;
    font-weight: bold;
    text-align: center;

    &::after {
      ${Triangle}
      border-bottom-color: #fff;
      top: auto;
      bottom: -16px;
      left: calc(50% - 12px);
    }
  }

  @media ${breakpoint.tabOnly} {
    margin: 60px 24px;
  }

  @media ${breakpoint.sp} {
    grid-gap: 8px;
    margin: 32px 12px;

    li {
      padding: 12px 0;
      font-size: 16px;
    }
  }
`;

const ThirdMethodWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    margin-top: 12px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    text-align: center;
  }

  span {
    color: ${ColorYellow};
  }

  @media ${breakpoint.sp} {
    div {
      font-size: 18px;
    }
  }
`;

const ThirdMethodVideo = styled.video`
  ${VideoSize}
`;

// 動画プラン
const GrayTitle = styled.h2`
  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;

  margin: 80px auto 0;
  padding: 16px 0;
  background: #ececec;
  border-radius: 4px;

  @media ${breakpoint.sp} {
    margin: 40px auto 0;
  }

  & > p {
    ${HeadingLarge}
    margin-left: 9px;
    &::before {
      left: -34px;
    }

    &::after {
      right: -28px;
    }
  }
`;
